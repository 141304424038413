import { AddPhotoAlternate, Edit, LocalOffer, MoreVertOutlined, PersonAddAlt, Refresh, Restore, SyncAlt, Update } from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
import { Box, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import styles from "../../../styles/theme/pageStyling/ListDetails.module.css";
import ViewUpdateDialog from "../../list/ViewUpdateDialog";
import {
  getInfluencerDataByInfluencerIds,
  getInfluencerDetailsByInfluencerId,
} from "../../../services/influencer/InfluencerDetails";
import { Search } from "@material-ui/icons";

function PostActions({
  influencerAccounts,
  postObject,
  editPost,
  editStory,
  refreshPerPostData,
  refreshPostInfluencerData,
  editTag,
  storyScreenshot,
  deleted,
  restorePost,
  setInfluencerList,
  setNotificationContent,
}) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const campId = parseInt(params.get("id"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [updateInfluencer, setUpdateInfluencer] = useState(false);
  const [influencerAccount, setInfluencerAccount] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  
  let infId = postObject.infId === "" ? 0 : postObject.infId.toString();

  let id = 1;
  const arrayOfOptions = [];

  if (postObject.status !== "Invalid URL" && deleted === "0") {
    if (postObject.postType === "Story") {
      arrayOfOptions.push({
        id: id++,
        icon: <SyncAlt className={styles.icon} />,
        label: "Update Stats",
        name: "updateStats",
      });
      arrayOfOptions.push({
        id: id++,
        icon: <AddPhotoAlternate className={styles.icon} />,
        label: "Story Screenshot",
        name: "storyScreenshot",
      });
    } else {
      arrayOfOptions.push({
        id: id++,
        icon: <Refresh className={styles.icon} />,
        label: "Refresh Post Stats",
        name: "refreshStats",
      });
    }
  }
  if (parseInt(infId) !== 0) {
    arrayOfOptions.push({
      id: id++,
      icon: <PersonIcon className={styles.icon} />,
      label: "Edit Influencer Profile",
      name: "updateInfluencer",
    });
  }

  if (parseInt(infId) === 0) {
    arrayOfOptions.push({
      id: id++,
      icon: <PersonAddAlt className={styles.icon} />,
      label: "Auto Assign Influencer",
      name: "assignInfluencer",
    });
  }

  if (deleted === "0") {
    arrayOfOptions.push({
      id: id++,
      icon: <Edit className={styles.icon} />,
      label: "Edit Post",
      name: "editPost",
    });
  } else if (deleted === "1") {
    arrayOfOptions.push({
      id: id++,
      icon: <Restore className={styles.icon} />,
      label: "Restore Post",
      name: "restorePost",
    });
  }

  if (postObject.status !== "Invalid URL" && deleted === "0") {
    if (postObject.tag !== "") {
      arrayOfOptions.push({
        id: id++,
        icon: <LocalOffer className={styles.icon} />,
        label: "Edit Tag",
        name: "editTag",
      });
    }
  }

  const invokeAction = async (name) => {
    switch (name) {
      case "updateInfluencer":
        if (infId != 0) {
          let type = "direct";
          if (infId < 100000000) {
            type = "organic";
          }
          const influencer = await getInfluencerDetailsByInfluencerId(infId, postObject.postData.platform, type);
          const influencerAccount = {
            ...influencer[0],
            platform: postObject.postData.platform,
            id:  Number(infId),
            type,
          };
          setInfluencerAccount(influencerAccount);
        } else {
          setInfluencerAccount({
            platform: postObject.postData.platform,
          });
        }

        setUpdateInfluencer(true);
        break;
      case "editPost":
        editPost(
          postObject.postData.id,
          postObject.postUrl,
          infId,
          postObject.postData.platform,
          postObject.postData.lid,
          postObject.id,
          postObject.postLanguage
        );
        break;
      case "updateStats":
        editStory(
          postObject.postData.id,
          postObject.platform,
          moment(postObject.timeData.createdDate).format("YYYY-MM-DD"),
          postObject.like,
          postObject.comment,
          postObject.share,
          postObject.view
        );
        break;
      case "refreshStats":
        refreshPerPostData(postObject.postData);
        break;
      case "assignInfluencer": 
        refreshPostInfluencerData(postObject.postData);
        break;
      case "editTag":
        editTag(postObject.id, postObject.tag);
        break;
      case "storyScreenshot":
        storyScreenshot(postObject);
        break;
      case "restorePost":
        restorePost(postObject.postData.id);
        break;
      default:
        break;
    }

    handleClose();
  };

  const getNotificationContent = (data) => {
    setNotificationContent(data);
  };

  const closeViewUpdateDialog = () => {
    setUpdateInfluencer(false);
  };

  const setInfluencerAccountList = (list) => {
    setInfluencerList(list);
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small" sx={{ ml: 1 }}>
        <MoreVertOutlined />
      </IconButton>
      <Box style={{ borderRadius: "30%" }}>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className={styles.menuitem}>
          {arrayOfOptions.map((value) => (
            <>
              <MenuItem key={value.id} onClick={() => invokeAction(value.name)}>
                {value.icon}&nbsp;&nbsp;&nbsp;&nbsp;{value.label}
              </MenuItem>
              {value.id < arrayOfOptions.length && <Divider sx={{ my: 0.5 }} />}
            </>
          ))}
        </Menu>
      </Box>
      {updateInfluencer && (
        <ViewUpdateDialog
          influencerAccounts={[]}
          platform={influencerAccount.platform}
          influencerAccount={influencerAccount}
          viewUpdateDialog={updateInfluencer}
          setNotificationContent={getNotificationContent}
          closeViewUpdateDialog={closeViewUpdateDialog}
          setInfluencerAccountList={setInfluencerAccountList}
        />
      )}
    </>
  );
}

export default PostActions;
