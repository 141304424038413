import { useEffect, useState } from "react";
import { array, bool, func, number, string } from "prop-types";
import { Alert, AlertTitle, Avatar, Box, Button, Grid, IconButton, Snackbar, Tooltip } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import styles from "../../styles/theme/pageStyling/ListDetails.module.css";
import ListActionButton from "./ListActionButton";
import ViewProfileDialog from "./ViewProfileDialog";
import { LabelTwoTone, Star } from "@mui/icons-material";
import ViewNoteDialog from "./ViewNoteDialog";
import {
  addNoteForInfluencer,
  removeInfluencerEmailFromList,
  removeInfluencerFromList,
} from "../../services/list/ListInfluencerMap";
import { getInfluencerInformation } from "../../services/influencer/InfluencerDetails";
import WarningIcon from "@mui/icons-material/Warning";
import CustomTable from "../../common/CustomTable/CustomTable";
import utils from "../../utils/utils";
import { ageData } from "../campaignAnalysis/snapshot";
import { PLATFORM_NAME } from "../../config/main.json";
import { useDispatch, useSelector } from "react-redux";
import { inviteInfluencerByEmail, inviteInfluencerByList } from "../../services/influencer/InfluencerInvite";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";

function getMissingText(data) {
  let detailsMissingText = "";
  const { name, total_followers, phone, username, email, age_range, gender, location } = data;
  // if all info present , then return nothing
  if (name && total_followers && phone && username && email && age_range && gender && location && email) return null;

  if (!name) formatText("Name");
  if (!total_followers || total_followers == 0) formatText("Followers");
  if (!phone) formatText("Phone");
  if (!username) formatText("Username");
  if (!email) formatText("Email");
  if (!age_range) formatText("Age Range");
  if (!gender) formatText("Gender");
  if (!location || location == "0") formatText("Location");
  return "Missing Details: " + detailsMissingText;

  function formatText(textKey) {
    return detailsMissingText.length > 0 ? (detailsMissingText += `, ${textKey}`) : (detailsMissingText += ` ${textKey}`);
  }
}

const noDataStyle = {
  noData: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    fontSize: "large",
    fontStyle: "italic",
  },
};

const ListTable = ({
  influencerAccount,
  setInfluencerList,
  removeInfluencer,
  influencerRemoved,
  disableRemoveBtn,
  total,
  getInfluencersData,
  filter,
  reload,
  setReload,
}) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const platform = params.get("platform");
  const listId = params.get("id");
  const cid = params.get("cid");
  const cname = params.get("cname");
  const [viewProfileDialog, setViewProfileDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [viewNoteDialog, setViewNoteDialog] = useState(false);
  const [tablePageNo, setTablePageNo] = useState(0); // page no starting from 1, as opposed to mui table (which starts from 0)
  const [tableRowsPerPage, setTableRowsPerPage] = useState(10);
  const [sortCol, setSortCol] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const tableRowHeaders = [
    {
      id: 1,
      alignment: "left",
      show: true,
      checkbox: true,
    },
    {
      id: 2,
      label: `${utils.capitalizeFirstLetter(platform)} Account`,
      sortParameter: "name",
      headerAssignment: "name",
      alignment: "center",
      numeric: false,
      percentage: false,
      show: true,
      sortable: true,
    },
    {
      id: 3,
      label: "Followers",
      sortParameter: "total_followers",
      headerAssignment: "total_followers",
      alignment: "left",
      numeric: true,
      percentage: false,
      show: true,
      sortable: true,
    },
    {
      id: 4,
      label: "Eng. Rate (ER)",
      sortParameter: "engagementrate",
      headerAssignment: "engagementrate",
      alignment: "left",
      numeric: true,
      percentage: true,
      show: true,
      sortable: true,
    },
    {
      id: 5,
      label: "Age",
      headerAssignment: "age",
      alignment: "center",
      numeric: false,
      percentage: false,
      show: true,
      sortable: false,
    },
    {
      id: 6,
      label: "Gender",
      headerAssignment: "gender",
      alignment: "center",
      numeric: false,
      percentage: false,
      show: true,
      sortable: false,
    },
    {
      id: 7,
      label: "Location",
      headerAssignment: "location",
      alignment: "left",
      numeric: false,
      percentage: false,
      show: true,
      sortable: false,
    },
    {
      id: 9,
      label: "Status",
      sortParameter: "status",
      headerAssignment: "status",
      alignment: "left",
      numeric: false,
      percentage: false,
      show: true,
    },
    {
      id: 10,
      label: "",
      sortParameter: "extraaction",
      headerAssignment: "extraaction",
      alignment: "center",
      numeric: false,
      percentage: false,
      show: true,
    },
    {
      id: 11,
      label: "",
      headerAssignment: "action",
      alignment: "center",
      numeric: false,
      percentage: false,
      show: true,
    },
  ];
  const [bodyColumns, setBodyColumns] = useState([]);
  const [additionalDetail, setAdditionalDetail] = useState({});
  const [selectedRows, setSelectedRows] = useState({});
  const getDetailedView = (event, influencerRow) => {
    setSelectedRow(influencerRow);
    retrieveInfluencerInformation(influencerRow);
    setViewProfileDialog(true);
  };

  const getNoteInformation = (event, influencerRow) => {
    setSelectedRow(influencerRow);
    setViewNoteDialog(true);
  };

  useEffect(() => {
    setTablePageNo(0);
    setTableRowsPerPage(10);
    getInfluencersData(false, 0, tableRowsPerPage, sortCol, sortOrder, filter);
  }, [filter]);

  useEffect(() => {
    if (reload) {
      getInfluencersData(true, tablePageNo, tableRowsPerPage, sortCol, sortOrder, filter);
      setReload(false);
    }
  }, [reload]);

  const handleInformationChange = ({ page, rowsPerPage, order, orderBy }) => {
    setTablePageNo(page);
    setTableRowsPerPage(rowsPerPage);
    setSortCol(orderBy);
    setSortOrder(order);
    getInfluencersData(false, page, rowsPerPage, orderBy, order, filter);
  };

  const inviteInfluencerToCampaign = (event, row, action) => {
    dispatch(
      openSnackbar({
        message: "Please wait... We are working on it",
        severity: "info",
      })
    );
    const notificationMetadata = {
      userId: user?.attributes?.identities ? JSON.parse(user?.attributes?.identities)[0]?.userId : "",
      sourcePlatform: PLATFORM_NAME,
      notificationTypeId: "4",
      notificationActionId: "2",
      notificationCategoryId: "5",
      generatedBy: user.attributes.name,
      priority: "medium",
      consumedBy: "gazelle",
    };
    if (row.id === 0) {
      inviteInfluencerByEmail(
        Number(cid),
        Number(listId),
        row.name,
        cname,
        platform?.toLowerCase(),
        action,
        notificationMetadata
      ).then((result) => {
        dispatch(
          openSnackbar({
            message: result.status,
            severity: "success",
          })
        );
      });
    } else {
      inviteInfluencerByList(
        Number(cid),
        Number(listId),
        [row.id],
        cname,
        platform?.toLowerCase(),
        action,
        notificationMetadata
      ).then((result) => {
        dispatch(
          openSnackbar({
            message: result.status,
            severity: "success",
          })
        );
      });
    }
  };

  useEffect(() => {
    const columns = [];
    influencerAccount
      .map((value) => {
        value["total_followers"] = value.followers;
        value.gender = utils.capitalizeFirstLetter(value.gender);
        return value;
      })
      .forEach((row, index) => {
        const missingText = getMissingText(row);
        console.log(row)
        var object = {
          id: row.id || row.name,
          name: (
            <a href={row.profileurl} target="_blank" rel="noreferrer">
              <Grid container>
                {row.isLeader && (
                  <Tooltip title="Leader">
                    <Grid item xs={2}>
                      {row.isLeader && <Star sx={{ width: 18, height: 18 }} style={{ color: "#ffd700" }} />}
                    </Grid>
                  </Tooltip>
                )}
                <Grid
                  xs={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {missingText && row?.status !== "Invited" && (
                    <Tooltip title={missingText}>
                      <WarningIcon style={{ color: "#FFA500" }} />
                    </Tooltip>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Avatar sx={{ bgcolor: deepOrange[500] }} variant="square" src={row.photoUrl} />
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "inline-block",
                        width: "100%",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <a href={row.profile_url} rel="noreferrer" target="_blank">
                        {row.name}
                      </a>
                      {row.type === "direct" && "*"}
                    </Grid>

                    <Grid item xs={12}>
                      <span
                        className={styles.username}
                        style={{
                          display: "inline-block",
                          width: "80%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {row.username !== "" && "@"} {row.username}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </a>
          ),
          total_followers: utils.convertToInternationalNumberSystem(row.followers),
          engagementrate: row.engagementrate,
          age: ageData.filter((value) => value.value === row.age_range)[0]?.text,
          gender: row.gender,
          location: row.location == "0" ? ("-") : (
            <Tooltip title={row.location}>
              <div style={{ cursor: "pointer" }}>{row.location.split(',')[0]}</div>
            </Tooltip>
          ),
          // score: row.score,
          profile_url: row.profileurl,
          addedOn: row.addedOn,
          status: ["Contracted", "Contract_pending"].includes(row.status) ? (
            <Tooltip title="Based on recent contract">
              <div>{row.status}</div>
            </Tooltip>
          ) : (
            row.status
          ),
          extraaction: (
            <Grid container>
              <Grid item xs={7}>
                <Tooltip title="View Note">
                  <>
                    {row?.note && (
                      <IconButton onClick={(event) => getNoteInformation(event, row)}>
                        <LabelTwoTone className={styles.icon} />
                      </IconButton>
                    )}
                  </>
                </Tooltip>
              </Grid>
            </Grid>
          ),
          action: (
            <Grid container spacing={1.5} style={{ display: "flex" }}>
              <Grid item xs={5}>
                {row.status?.toLowerCase() !== "added-direct" &&
                  row.status?.toLowerCase() !== "contracted" &&
                  row.status?.toLowerCase() !== "contract_pending" &&
                  row.status?.toLowerCase() !== "invited" && (
                    <Button
                      variant="contained"
                      type="submit"
                      size="small"
                      style={{ color: "white", backgroundColor: "#51A6FF" }}
                      className={styles.button}
                      onClick={(event) => inviteInfluencerToCampaign(event, row, "invite")}
                    >
                      Invite Influencer
                    </Button>
                  )}
                {row.status?.toLowerCase() === "invited" && (
                  <Button
                    variant="contained"
                    type="submit"
                    size="small"
                    style={{ color: "white", backgroundColor: "#51A6FF" }}
                    className={styles.button}
                    onClick={(event) => inviteInfluencerToCampaign(event, row, "resendInvite")}
                  >
                    Resend Invite
                  </Button>
                )}
              </Grid>
              <Grid item xs={3}>
                <ListActionButton
                  influencerAccount={row}
                  influencerAccounts={influencerAccount}
                  setInfluencerList={setInfluencerList}
                />
              </Grid>
            </Grid>
          ),
        };
        columns.push(object);
      });
    setBodyColumns(columns);
  }, [influencerAccount, platform]);

  useEffect(() => {
    if (removeInfluencer === true) {
      dispatch(
        openSnackbar({
          message: "Please wait... We are working on it",
          severity: "info",
        })
      );
      const ids = Object.keys(selectedRows);
      let intids = [];
      intids = ids.map((id) => parseInt(id));
      if (!intids[0]) {
        removeInfluencerEmailFromList(ids, listId).then((result) => {
          if (result) {
            dispatch(
              openSnackbar({
                message: "Influencer Removed from list successfully",
                severity: "success",
              })
            );
            influencerRemoved(false);
            setTablePageNo(0);
            getInfluencersData(true, tablePageNo, tableRowsPerPage, sortCol, sortOrder, filter);
          }
        });
      } else {
        removeInfluencerFromList(intids, listId).then((result) => {
          if (result) {
            dispatch(
              openSnackbar({
                message: "Influencer Removed from list successfully",
                severity: "success",
              })
            );
            influencerRemoved(false);
            setTablePageNo(0);
            getInfluencersData(true, tablePageNo, tableRowsPerPage, sortCol, sortOrder, filter);
          }
        });
      }
    }
  }, [removeInfluencer]);

  const retrieveInfluencerInformation = (influencerRow) => {
    getInfluencerInformation(influencerRow.id, platform, influencerRow.type).then((result) => {
      if (result && result.length > 0) setAdditionalDetail(result[0]);
    });
  };

  const closeViewProfileDialog = () => {
    setViewProfileDialog(false);
  };

  const closeViewNoteDialog = () => {
    setViewNoteDialog(false);
  };

  const setInfluencerAccountList = (list) => {
    setInfluencerList(list);
  };

  const sendInformation = (information) => {
    addNoteForInfluencer(Number(listId), selectedRow.id, information.note, cid).then((result) => {
      if (result) {
        updateInfluencerList(result, "note", information);
        dispatch(
          openSnackbar({
            message: result.status,
            severity: result.error === false ? "success" : "error",
          })
        );
        setViewNoteDialog(false);
      }
    });
  };

  const updateInfluencerList = (result, functionality, information) => {
    if (influencerAccount && influencerAccount.length > 0) {
      const accountList = [];
      influencerAccount.forEach((account) => {
        if (account.id === selectedRow.id) {
          if (functionality === "note") {
            account.note.push({
              id: result.id,
              note: information.note,
              createdAt: information.createdAt,
            });
          }
        }
        accountList.push(account);
      });
      setInfluencerAccountList(accountList);
    }
  };

  const rowSelectionChanged = (rowIdsSelected) => {
    delete rowIdsSelected[0];
    setSelectedRows(rowIdsSelected);
    if (Object.keys(rowIdsSelected).length > 0) {
      disableRemoveBtn(false);
    } else disableRemoveBtn(true);
  };

  return (
    <>
      {viewNoteDialog && (
        <ViewNoteDialog
          functionality={"note"}
          influencerAccount={selectedRow}
          viewNoteDialog={viewNoteDialog}
          closeViewNoteDialog={closeViewNoteDialog}
          sendInformation={sendInformation}
        />
      )}
      {viewProfileDialog && (
        <ViewProfileDialog
          influencerAccount={selectedRow}
          viewProfileDialog={viewProfileDialog}
          closeViewProfileDialog={closeViewProfileDialog}
          additionalDetail={additionalDetail}
          platform={platform}
          engagementDisplay={true}
        />
      )}
      <div id="table">
        {bodyColumns.length > 0 && (
          <CustomTable
            tableHeaders={tableRowHeaders}
            bodyColumns={bodyColumns}
            selectedRow={selectedRow}
            rowSelectionChanged={rowSelectionChanged}
            sendInformation={handleInformationChange}
            totalRows={total}
            pageNo={tablePageNo}
            rowsPerPageNo={tableRowsPerPage}
            sortOrder={sortOrder}
            sortOrderBy={sortCol}
          />
        )}
        {bodyColumns.length === 0 && (
          <Box my={2}>
            <Grid container spacing={2}>
              <Grid style={noDataStyle.noData} item xs={12}>
                No Influencer Found
              </Grid>
            </Grid>
          </Box>
        )}
      </div>
    </>
  );
};

ListTable.propTypes = {
  influencerAccount: array.isRequired,
  setInfluencerList: func.isRequired,
  removeInfluencer: bool.isRequired,
  influencerRemoved: func.isRequired,
  total: number.isRequired,
  getInfluencersData: func.isRequired,
  filter: string.isRequired,
  reload: bool.isRequired,
  setReload: func.isRequired,
};

export default ListTable;
