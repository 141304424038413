import React, { useEffect, useState } from "react";
import { Box, Grid, MenuItem, Divider, Menu, Typography, ButtonGroup, Autocomplete, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { API_HOST } from "../../config/main.json";
import InsertLinkTwoToneIcon from "@mui/icons-material/Link";
import DriveIcon from "@mui/icons-material/AddToDrive";
import { DRIVE_LINK, SLACK_LINK } from "../../config/main.json";
import { changeCampaignStatus, getCampaignList, getAccountCampaignList } from "../../services/campaign/CampaignDetails";
import CustomTable from "../../common/CustomTable/CustomTable";
import CustomTabs from "../../common/CustomTabs/CustomTabs";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import {
  AddCircle,
  Analytics,
  Archive,
  Assessment,
  Circle,
  Delete,
  Edit,
  MoreVertOutlined,
  Pause,
  PlayArrow,
  Visibility,
} from "@mui/icons-material";
import tableStyle from "../../styles/theme/pageStyling/TableStyle.module.css";
import SearchItem from "./SearchItem";
import Loader from "./Loader";
import { getTimeZone, onDownloadHandle } from "./../../utils/exportData";
import ExportFiles from "./ExportFiles";
import GeneratePptReport from "../../components/generatePpt/GeneratePptReport";

import { useDispatch, useSelector } from "react-redux";
import { campaignStatusColors, snapshotHeaderIcons, textColor, statusToNum } from "../../components/campaignAnalysis/snapshot";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";
import { convertToUserTimezone, getFormattedDate } from "../../utils/momentManipulations";
import { pink } from "@mui/material/colors";
import { getTableHeaders, gettableHeadersArchived } from "../../utils/tableHeaders";
import FolderIcon from "@mui/icons-material/Folder";
import SearchIcon from "@mui/icons-material/Search";
import utils from "../../utils/utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CampaignTable = (props) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const accId = params.has("accId") ? Number(params.get("accId")) : null;
  const [campaignData, setCampaignData] = useState([]); //refers to list of campaign with its details in compliance with page on
  const [totalActive, setTotalActive] = useState(0); //  refers to no of total active campaign
  const [totalInactive, setTotalInactive] = useState(0); // refers to no of total archived campaign
  const [totalDeleted, setTotalDeleted] = useState(0); // refers to no of total deletd campaign
  const [tableData, setTableData] = useState([]);
  const [anchorEl, setAnchorEl] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [tablePageNo, setTablePageNo] = useState(0); // refers to page no of pagination (default - 0)
  const [tableRowsPerPage, setTableRowsPerPage] = useState(10); //refers to no of rows dispaly at a time on page
  const [resetPageNo, setResetPageNo] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [sortCol, setSortCol] = useState("createdAt"); //refers on what base all col are sorted
  const [account, setAccount] = useState(accId); //  refers to  account (0 - aifluence)
  const [type, setType] = useState(1); // refers to type of campaigns(1= active, 0 = archived, 2 = deleted)
  const [selectedTab, setSelectedTab] = useState("0");
  const [isExport, setIsExport] = useState(0); // refers we want to export or not, 0 - not, 1 - yes
  const [sortOrder, setSortOrder] = useState("desc"); //refers on what base all rows are sorted
  const [filter, setFilter] = useState(""); // refers to the value/campaigns we are searching in seacrch field
  const [mimeType] = useState("csv"); //refers to the type in which we want to export
  const [expand] = useState([]);
  const [totalRecords, setTotalRecords] = useState(); // refers to total no of campaigns
  const [dataFound, setDataFound] = useState(false); // shows that we have/recive campaigns data from API or not
  const [campaignPlatform, setCampaignPlatform] = useState(props.platfrom ? props.platfrom : "oryx"); //defines which type of campaigns wil be display oryx/empowr
  const [pptReportId, setPptReportId] = useState(0); // refers to the id of Campaign for which we want to Generate Report
  const [searchBy] = useState("Campaign Name"); // as we seacrch only by Campaign Name(used in palceholder)
  const [accounts, setAccounts] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [accountName, setAccountName] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const timeZone = getTimeZone(); // to set the time on platform

  const styles = {
    noData: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      fontSize: "large",
      fontStyle: "italic",
    },
    dataStyle: {
      fontSize: "0.85em",
      fontStyle: "italic",
      margin: 0,
      padding: 0,
      lineHeight: 1,
    },
    noDataText: {
      textAlign: "center",
      fontSize: "large",
      paddingTop: 20,
    },
    centerContainer: {
      height: "50vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    iconContainer: {
      color: "#666",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      fontSize: 60,
    },
    searchIcon: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "#fff",
      fontSize: "1.5rem",
    },
  };

  const getAccountsList = (platform, type) => {
    axios.get(`${API_HOST}/account/campaign/list/${type}/${platform}`).then((res) => {
      const accountData = res.data;
      setAccounts(accountData);
      if (params.has("accId")) {
        const selected = accountData.find((acc) => acc.id === account);
        if (selected) {
          setAccountName(selected.name);
        }
      }
    });
  };

  useEffect(() => {
    getAccountsList(campaignPlatform, type);
  }, []);

  const fetchData = () => {
    setIsLoaded(() => false);
    let type = 1
    if (props.type === 0) {
      setType(0);
      setSelectedTab("1")
      getAccountsList(campaignPlatform, 0);
      type = 0;
    } else if (props.type === 2) {
      setType(2);
      setSelectedTab("2")
      getAccountsList(campaignPlatform, 2);
      type = 2;
    }
    else {
      setType(1);
      setSelectedTab("0")
      getAccountsList(campaignPlatform, 1);
      type = 1;
    }
    getCampaignData(
      account,
      type,
      isExport,
      tablePageNo,
      tableRowsPerPage,
      sortCol,
      sortOrder,
      filter,
      mimeType,
      campaignPlatform,
      timeZone,
      selectedStatus
    );
  };
  const getStatusOption = (type) => {
    let options = Object.keys(statusToNum).filter((status) => !["Archived", "Archived_Draft", "Deleted"].includes(status));
    if (type === 0) options = ["Archived", "Archived_Draft"];
    if (type === 2) options = ["Deleted"];
    return options;
  };

  useEffect(() => {
    fetchData();
  }, [
    account,
    type,
    isExport,
    tablePageNo,
    tableRowsPerPage,
    sortCol,
    sortOrder,
    filter,
    mimeType,
    campaignPlatform,
    selectedStatus,
  ]);

  const getCampaignData = async (
    account,
    type,
    isExport,
    tablePageNo,
    tableRowsPerPage,
    sortCol,
    sortOrder,
    filter,
    mimeType,
    campaignPlatform,
    timeZone,
    selectedStatus
  ) => {
    setIsLoaded(false);
    // API used to get the details of the campaigns
    let res;
    if (params.has("accId")) {
      res = await getAccountCampaignList(
        account,
        type,
        isExport,
        tablePageNo + 1,
        tableRowsPerPage,
        sortCol,
        sortOrder,
        filter,
        mimeType,
        campaignPlatform,
        timeZone,
        selectedStatus
      );
    }
    else {
      res = await getCampaignList(
        account,
        type,
        isExport,
        tablePageNo + 1,
        tableRowsPerPage,
        sortCol,
        sortOrder,
        filter,
        mimeType,
        campaignPlatform,
        timeZone,
        selectedStatus
      );
    }

    if (isExport === 1) {
      onDownloadHandle(res, "Campaigns." + mimeType);
      setIsExport(0);
    }

    //console.log(res);
    const { total_records, campaignList, active, inactive, deleted } = res;

    setTotalActive(active); // active - gives a number that represent total active campaign
    setTotalInactive(inactive); // inactive - gives a number that represent total archived campaign
    setTotalDeleted(deleted); // deleted - gives a number that represent total deleted campaign

    if (total_records > 0 && campaignList) {
      setCampaignData(campaignList); // campaignList - gives the list of campaign of a particular page (not all)
      setTotalRecords(total_records); // total_records - refers to no of campaign individualy ( active,archived,deleted )
      setDataFound(true);
    } else {
      setDataFound(false);
    }

    setIsLoaded(true);
  };

  /**
   * @param {*} id refers to campaign for which we want to change the status
   * @param {*} status refers to the current status of the campaign(1-live,3-pause, 4-draft, 6-archive, 7-archive the draft campaign, 8-delete)
   */
  const updateCampaignStatus = async (id, status) => {
    dispatch(
      openSnackbar({
        message: "Please wait... we are refreshing your campaign statuses",
        severity: "info",
      })
    );

    const res = await changeCampaignStatus(id, status);
    fetchData();
    setAnchorEl([]);
  };

  const handleInformationChange = (newPage, rowsPerPage, order, orderBy) => {
    setTablePageNo(newPage.page);
    setResetPageNo(false);
    setIsChanged(!isChanged);
    setTableRowsPerPage(newPage.rowsPerPage);
    setSortCol(newPage.orderBy);
    setSortOrder(newPage.order);
  };

  // function to set search value in "filter" variable and change page no to 0
  const sendSearchedValue = (event) => {
    setFilter(event);
    setTablePageNo(0);
  };

  // function to set in which from(csv) we want to export and decide whether we want to export or not
  const sendExportSelected = (event) => {
    setIsExport(event === "csv" ? 1 : 0);
  };

  const generateToolTipTextUnarchived = (item) => {
    const { startDate, endDate, createdAt, updatedAt } = item;
    let sDate = startDate !== "" ? new Date(startDate) : "";
    let cDate = new Date(createdAt);
    let uDate = new Date(updatedAt);
    const ttText =
      (sDate !== "" ? "Start Date: " + sDate.toUTCString() + "\n " : "") +
      "Created Date: " +
      cDate.toUTCString() +
      "\n Updated Date: " +
      uDate.toUTCString();
    return ttText;
  };

  useEffect(() => {
    const handleClick = (event, index) => {
      const anchorEl = [];
      for (let i = 0; i < campaignData.length; i++) {
        if (i === index) anchorEl.push(event.currentTarget);
        else anchorEl.push(null);
      }
      setAnchorEl(anchorEl);
    };

    const handleClose = () => {
      const anchorEl = [];
      for (let i = 0; i < campaignData.length; i++) {
        anchorEl.push(null);
      }
      setAnchorEl(anchorEl);
    };

    const data = [];
    if (campaignData) {
      campaignData.forEach((item, index) => {
        const {
          id,
          universal,
          name,
          companyId,
          companyName,
          platform,
          postCount,
          weekEngg,
          influencerCount,
          daysDiff,
          endDate,
          current_status,
          listCount,
          startDate,
          brandName,
          gdriveId,
          slackId,
        } = item;
        const formattedEndDate = daysDiff > 0 ? `${daysDiff} Days` : <p style={{ color: "#f08995" }}>Finished</p>;
        //If daysDiff is greater than 0, it will format the 'formattedEndDate' as ${daysDiff} Days.
        //If daysDiff is not greater than 0, it will display "Finished" with a specified style.
        const formattedStartDate = getFormattedDate(startDate, "MMMM Do YYYY");
        // getFormattedDate - parse the input startDate and then format it according to the provided format
        const endDateText =
          daysDiff > 0
            ? `Ends ${getFormattedDate(convertToUserTimezone(endDate), "MMMM Do YYYY")}`
            : `Ended on ${getFormattedDate(endDate, "MMMM Do YYYY")}`;
        //convertToUserTimezone - takes a UTC timestamp as input and converts it to the user's timezone.
        const links = {};
        if (current_status !== "Draft") {
          if (gdriveId === "na" || gdriveId === "") {
            links["drive"] = "#";
          } else {
            var driveLink = DRIVE_LINK + "" + gdriveId;
            links["drive"] = driveLink;
          }

          if (slackId === "na" || slackId === "") {
            links["slack"] = "#";
          } else {
            links["slack"] = SLACK_LINK + "" + slackId;
          }
        }
        // Deals with link so that can pass in menu
        const circleColor = campaignStatusColors[current_status];
        // variable 'circleColor' based on the current status (current_status) of a campaign. It retrieves the color from the
        // campaignStatusColors object using the current status as the key.
        // campaignStatusColors is objectthat maps different campaign statuses to their respective colors
        const status = (
          <Grid container alignItems="center" justifyContent="flex-start" spacing={1}>
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "15px",
                border: `1px solid ${circleColor} `,
                px: 1,
                py: 0.5,
                width: "fit-content",
              }}
            >
              <Grid>
                <Circle htmlColor={circleColor} sx={{ width: "20px", height: "20px" }} />
              </Grid>
              <Grid sx={{ marginLeft: 0.5 }}>{current_status}</Grid>
            </Box>
          </Grid>
        );

        // deals with actions on active campaigns page
        const actions = (
          <div>
            {["Live", "Paused", "Completed", "Completed"].includes(current_status) && (
              <>
                <MenuItem component={Link} to={"../campaigns/analysis?id=" + id}>
                  <Analytics className={tableStyle.icon} sx={{ color: "#0000FF" }} />
                  &nbsp;&nbsp;&nbsp;&nbsp;View Campaign Analytics
                </MenuItem>
              </>
            )}
            {/* {["Completed"].includes(current_status) && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem component={Link} to={"../campaigns/edit?id=" + id}>
                  <Article className={tableStyle.icon} sx={{ color: "#006400" }} />
                  &nbsp;&nbsp;&nbsp;&nbsp;View Campaign Details
                </MenuItem>
              </>
            )} */}
            {["Draft"].includes(current_status) && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  onClick={(event) => {
                    if (window.confirm("Are you sure you want to archive the draft campaign?")) {
                      updateCampaignStatus(id, statusToNum.Archived_Draft); // will change the status of campaign to archive from draft
                    }
                  }}
                >
                  <Archive className={tableStyle.icon} sx={{ color: "#336699" }} />
                  &nbsp;&nbsp;&nbsp;&nbsp;Archive this Draft
                </MenuItem>
              </>
            )}
            {["Completed", "Paused"].includes(current_status) && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  onClick={(event) => {
                    if (
                      window.confirm("Are you sure you want to archive the campaign?\n\nThis will archive related lists too.")
                    ) {
                      updateCampaignStatus(id, statusToNum.Archived); // to archive from Paused or Completed
                    }
                  }}
                >
                  <Archive className={tableStyle.icon} sx={{ color: "#808080" }} />
                  &nbsp;&nbsp;&nbsp;&nbsp;Archive this campaign
                </MenuItem>
              </>
            )}

            {!["Archived", "Archived Draft", "Deleted"].includes(current_status) &&
              ["super-user", "manager"].includes(user.group) && (
                <>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem component={Link} to={"../campaigns/edit?id=" + id + "&isEdit=true"}>
                    <Edit className={tableStyle.icon} sx={{ color: "#008000" }} />
                    &nbsp;&nbsp;&nbsp;&nbsp;Edit this Campaign
                  </MenuItem>
                </>
              )}

            {!["Archived", "Archived Draft", "Deleted"].includes(current_status) &&
              ["super-user", "manager"].includes(user.group) && (
                <>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem component={Link} to={"../campaigns/viewdetails?id=" + id + "&isEdit=false"}>
                    <Visibility className={tableStyle.icon} sx={{ color: "#006400" }} />
                    &nbsp;&nbsp;&nbsp;&nbsp;View this Campaign
                  </MenuItem>
                </>
              )}

            {["Live"].includes(current_status) && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  onClick={(event) => {
                    if (window.confirm("Are you sure you want to pause the campaign?")) {
                      updateCampaignStatus(id, statusToNum.Paused);
                    }
                  }}
                >
                  <Pause className={tableStyle.icon} sx={{ color: "#FFA500" }} />
                  &nbsp;&nbsp;&nbsp;&nbsp;Pause this Campaign
                </MenuItem>
              </>
            )}
            {["Paused"].includes(current_status) && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  onClick={(event) => {
                    if (window.confirm("Are you sure you want to resume the campaign?")) {
                      const startDateNew = new Date(startDate.toString());
                      const endDateNew = new Date(endDate.toString());
                      const currentDate = new Date();
                      let statusId = 0;
                      if (startDateNew < currentDate && currentDate <= endDateNew) {
                        statusId = statusToNum.Live;
                      } else if (currentDate > endDateNew) {
                        statusId = statusToNum.Completed;
                      } else {
                        statusId = statusToNum.Scheduled;
                      }
                      updateCampaignStatus(id, statusId);
                    }
                  }}
                >
                  <PlayArrow className={tableStyle.icon} sx={{ color: "#0000cc" }} />
                  &nbsp;&nbsp;&nbsp;&nbsp;Resume this campaign
                </MenuItem>
              </>
            )}
            {["Live"].includes(current_status) && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem component={Link} to={"../campaigns/addPost?id=" + id}>
                  <AddCircle className={tableStyle.icon} sx={{ color: "#800080" }} />
                  &nbsp;&nbsp;&nbsp;&nbsp;Add posts
                </MenuItem>
              </>
            )}
            {["Live", "Completed"].includes(current_status) && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setPptReportId(id);
                  }}
                >
                  <Assessment className={tableStyle.icon} sx={{ color: "#00cc00" }} />
                  &nbsp;&nbsp;&nbsp;&nbsp;Generate Report
                </MenuItem>
              </>
            )}
            {["Archived", "Archived Draft"].includes(current_status) && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  onClick={(event) => {
                    if (new Date() > new Date(endDate)) {
                      if (window.confirm("Are you sure you want to restore the campaign?")) {
                        updateCampaignStatus(id, statusToNum.Archived);
                      }
                    } else if (new Date() > new Date(startDate) && new Date() < new Date(endDate)) {
                      if (
                        window.confirm("Are you sure you want to restore the campaign?\n\nNote - It will become a Live campaign")
                      ) {
                        updateCampaignStatus(id, statusToNum.Live);
                      }
                    }
                  }}
                >
                  <UnarchiveIcon className={tableStyle.icon} sx={{ color: "#808080" }} />
                  &nbsp;&nbsp;&nbsp;&nbsp;Unarchive this Campaign
                </MenuItem>
              </>
            )}
            {["super-user"].includes(user.group) && !["Live", "Deleted"].includes(current_status) && !universal && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  onClick={(event) => {
                    if (
                      window.confirm(`Are you sure you want to delete this ${current_status} campaign?`)
                    ) {
                      updateCampaignStatus(id, statusToNum.Deleted);
                    }
                  }}
                >
                  <Delete className={tableStyle.icon} sx={{ color: pink[500] }} />
                  &nbsp;&nbsp;&nbsp;&nbsp;Delete this Campaign
                </MenuItem>
              </>
            )}
            {["Deleted"].includes(current_status) && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  onClick={(event) => {
                    if (window.confirm("Are you sure you want to restore this campaign to archive?")) {
                      updateCampaignStatus(id, statusToNum.Archived); // Change the status to Archived
                    }
                  }}
                >
                  <UnarchiveIcon className={tableStyle.icon} sx={{ color: "#808080" }} />
                  &nbsp;&nbsp;&nbsp;&nbsp;Back to Archive
                </MenuItem>
              </>
            )}
            {["Deleted"].includes(current_status) && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                  onClick={(event) => {
                    if (window.confirm("Are you sure you want to delete this campaign forever?")) {
                      axios.get(`${API_HOST}/campaign/delete/` + id).then((res) => {
                        window.location = "/campaigns/deleted"; //This line of code will redirect you once the submission is succeed
                      });
                    }
                  }}
                >
                  <Delete className={tableStyle.icon} sx={{ color: pink[500] }} />
                  &nbsp;&nbsp;&nbsp;&nbsp;Delete this Campaign Forever
                </MenuItem>
              </>
            )}
            {links["drive"] && campaignPlatform == "oryx" && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={() => window.open(links["drive"], "_blank")}>
                  <DriveIcon className={tableStyle.icon} sx={{ color: "#3C8039", mr: 2 }} />
                  Drive Folder
                </MenuItem>
              </>
            )}
            {links["slack"] && campaignPlatform == "oryx" && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={() => window.open(links["slack"], "_blank")}>
                  <InsertLinkTwoToneIcon className={tableStyle.icon} sx={{ color: "#E01E5A", mr: 2 }} />
                  Slack Channel
                </MenuItem>
              </>
            )}
          </div>
        );
        const open = anchorEl.map((val) => val !== null);

        // platformIcons is an array , that use optional chaining operator (?.) to safely access the map method of the platform array.
        // platform(array) is from api containing all platfrom(plat) used for the campaign.
        // Typography(obj) determines color and snapshotHeaderIcons(obj)determines the icon for diff platfrom using plat as key.
        const platformIcons = platform?.map((plat) => (
          <Grid item xs={1} md={1} className={styles.bodycell}>
            <Typography sx={{ ...textColor[plat.name.toLowerCase()] }}>
              {snapshotHeaderIcons[plat.name.toLowerCase()]
                ? React.cloneElement(snapshotHeaderIcons[plat.name.toLowerCase()], { style: { fontSize: plat.name.toLowerCase() === 'tiktok' ? 'calc(1rem + 0.1vw)' : 'calc(1.3rem + 0.1vw)' } })
                : null}
            </Typography>
          </Grid>
        ));

        const object = {
          id: index + 1,
          name: (
            <>
              <Grid container spacing={1} sx={{ justifyContent: "flex-start" }}>
                <Grid item md={8} sm={5} sx={{ marginTop: 2 }}>
                  <Box
                    component={"a"}
                    sx={{
                      cursor: "pointer",
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 800,
                      fontSize: "1.1rem",
                      lineHeight: "1rem",
                      color: "#1461cc",
                    }}
                    href={`../campaigns/analysis?id=${id}`}
                  >
                    {name}
                  </Box>
                  <div style={{ paddingLeft: "1px", color: "#999" }}>{companyName}</div>
                </Grid>
                <Grid item md={4} sm={2} sx={{ marginBlock: "auto", paddingLeft: 2 }}>
                  <Grid container spacing={4}>
                    {platformIcons}
                  </Grid>
                </Grid>
              </Grid>
            </>
          ),
          // brandName: brandName,
          postCount: (
            <Grid container>
              <Grid item xs={12} sx={{ paddingLeft: "8px" }}>
                {postCount}
              </Grid>
            </Grid>
          ),
          influencerCount: (
            <Grid container>
              <Grid item xs={12} sx={{ paddingLeft: "20px" }}>
                {influencerCount}
              </Grid>
            </Grid>
          ),
          weekEngg: (
            <Grid container>
              <Grid item xs={12} sx={{ paddingLeft: "20px" }}>
                {utils.convertToInternationalNumberSystem(weekEngg)}
              </Grid>
            </Grid>
          ),
          endDate: (
            <Grid container>
              <Grid item xs={12}>
                <p className={styles.bodycell}>{formattedEndDate}</p>
              </Grid>
              <Grid item xs={12} style={styles.dataStyle} className={styles.subbodycell}>
                {endDateText}
              </Grid>
            </Grid>
          ),
          current_status: status,
          action: (
            <Grid container>
              <Grid item xs={3}>
                <>
                  {" "}
                  <IconButton onClick={(event) => handleClick(event, index)} size="small" sx={{ ml: 2 }}>
                    <MoreVertOutlined />
                  </IconButton>
                  <Box style={{ borderRadius: "30%" }}>
                    <Menu
                      anchorEl={anchorEl[index]}
                      getContentAnchorEl={null}
                      open={open[index]}
                      onClose={handleClose}
                      className={tableStyle.menuitem}
                    >
                      {actions}
                    </Menu>
                  </Box>
                </>
              </Grid>
            </Grid>
          ),
          startDate: (
            <Grid container spacing={1} className={styles.bodycell}>
              {formattedStartDate}
            </Grid>
          ),
        };

        data.push(object);
      });
      setTableData(data);
    }
  }, [campaignData, anchorEl]);

  const tableHeaders = getTableHeaders;

  const tableHeadersArchived = gettableHeadersArchived;

  const renderContentHandler = () => {
    let content = null;
    if (isLoaded) {
      if (dataFound) {
        content = (
          <>
            <div id="table">
              {/* This is a custom made component, take care while changing field names */}
              <CustomTable
                tableHeaders={type == 1 ? tableHeaders : tableHeadersArchived}
                bodyColumns={tableData}
                sendInformation={handleInformationChange}
                totalRows={totalRecords}
                pageNo={tablePageNo}
                rowsPerPageNo={tableRowsPerPage}
                sortOrder={sortOrder}
                sortOrderBy={sortCol}
              />
            </div>
            {/* This is a custom made component, take care while changing field names */}
            <GeneratePptReport pptReportId={pptReportId} setPptReportId={setPptReportId} needPastReports={true} />
          </>
        );
      } else {
        content = (
          <Grid container style={styles.centerContainer}>
            <Box style={styles.iconContainer}>
              <FolderIcon style={{ fontSize: "4rem" }} />
              <SearchIcon style={styles.searchIcon} />
            </Box>
            <div style={styles.noDataText}>No matching Campaigns found.</div>
          </Grid>
        );
      }
    } else {
      content = <Loader />;
    }

    return content;
  };

  const tabArray = [
    {
      label: `Active Campaigns (${totalActive})`,
      id: "account-details-tab-0",
      to: `/campaigns?platform=${campaignPlatform}`,
      component: Link,
    },
    {
      label: `Archived Campaigns (${totalInactive})`,
      id: "account-details-tab-0",
      to: `/campaigns/archived?platform=${campaignPlatform}`,
      component: Link,
    },
    {
      label: `Deleted Campaigns (${totalDeleted})`,
      id: "account-details-tab-0",
      to: `/campaigns/deleted?platform=${campaignPlatform}`,
      component: Link,
    },
  ]

  const handleAccountChange = (event, value) => {
    if (value !== null) {
      const selected = accounts.find((acc) => acc.name === value);
      if (selected) {
        setAccount(selected.id);
        setAccountName(selected["name"]);
      } else {
        // If no valid account is found, reset the state
        setAccount(0);
        setAccountName('');
      }
    } else {
      // If value is null (selection is cleared), reset the state
      setAccount(0);
      setAccountName('');
    }
  };

  const campaignPlatformHandler = (platform) => {
    setCampaignPlatform(platform);
    setTablePageNo(0);
    if (type === 2) {
      navigate(`/campaigns/deleted?platform=${platform}`);
    } else if (type === 0) {
      navigate(`/campaigns/archived?platform=${platform}`);
    } else {
      navigate(`/campaigns?platform=${platform}`);
    }
  };

  return (
    <>
      {/* the first navbar of the page  */}
      <Box mt={4} mb={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
        <Box display={"flex"} alignItems={"center"}>
          <Typography variant={"h5"} mr={2}>
            Platform:{" "}
          </Typography>
          <ButtonGroup variant="outlined" aria-label="platform button group" disableElevation>
            <Button
              variant={campaignPlatform === "oryx" ? "contained" : "outlined"}
              onClick={() => campaignPlatformHandler("oryx")}
            >
              Oryx
            </Button>
            <Button
              variant={campaignPlatform === "empowr" ? "contained" : "outlined"}
              onClick={() => campaignPlatformHandler("empowr")}
            >
              Empowr
            </Button>
          </ButtonGroup>
        </Box>
        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <SearchItem sendSearchedValue={sendSearchedValue} searchBy={searchBy} />
          <ExportFiles sendExportSelected={sendExportSelected} />
        </Box>
      </Box>

      <Box mt={0} mb={3} display="flex" alignItems="left" justifyContent="space-between">
        <Box display="flex">
          <CustomTabs tabArray={tabArray} selectedTab={selectedTab} />
        </Box>
        {["super-user", "manager"].includes(user.group) && campaignPlatform === "oryx" && (
          <Button variant="contained" component={Link} to="../campaigns/add" style={{ marginRight: "21px", height: '50%' }}>
            Create Campaign
          </Button>
        )}
      </Box>
      {campaignPlatform === "empowr" && <br />}
      <Box m={1}>
        <Stack direction="row" justifyContent="end">
          <Grid container spacing={0.5}>
            <Grid item xs={12} sm={6} md={6}></Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Autocomplete
                size="small"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    fontSize: "0.875rem",
                    borderRadius: "10px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.875rem",
                  },
                }}
                disablePortal
                id="filter-by-account"
                options={accounts.map((option) => option.name)}
                value={accountName}
                onChange={handleAccountChange}
                renderInput={(params) => (
                  <TextField {...params} label="Filter by Account" variant="outlined" size="small" MenuProps={MenuProps} />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3} md={3}>
              <Autocomplete
                size="small"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    fontSize: "0.875rem",
                    borderRadius: "10px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "0.875rem",
                  },
                }}
                disablePortal
                id="filter-by-status"
                options={getStatusOption(type)}
                onChange={(event, value) => setSelectedStatus(value !== null ? statusToNum[value].toString() : "")}
                renderInput={(params) => (
                  <TextField {...params} label="Filter by Status" variant="outlined" size="small" MenuProps={MenuProps} />
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <Box my={1}>{renderContentHandler()}</Box>
    </>
  );
};

export default CampaignTable;
